<template>
  <ion-page>
    <Header></Header>
    <ion-content :fullscreen="true" class="ion-padding-start ion-padding-end ion-padding-top">
      <ion-card color="light" class="ion-margin-start">
        <ion-card-header>
          <ion-card-subtitle>{{ getUserData('email') }}</ion-card-subtitle>
          <ion-card-title>{{ getUserData('displayName') }}</ion-card-title>
        </ion-card-header>

        <ion-card-content>
          <ion-button size="small" fill="outline" @click="doLogout">Log out</ion-button>
        </ion-card-content>
      </ion-card>
    </ion-content>
  </ion-page>
</template>

<script lang="js">
import store from '@/store'
import auth from '@/mixins/auth'
import {IonPage, IonContent, IonButton, IonCard, IonCardContent, IonCardHeader, IonCardSubtitle, IonCardTitle} from '@ionic/vue';
import Header from "@/components/global/Header";


export default {
  name: 'Tab2',
  components: {Header, IonContent, IonPage, IonButton, IonCard, IonCardContent, IonCardHeader, IonCardSubtitle, IonCardTitle},
  mixins: [auth],
  data() {
    return {
      state: store.state,
    }
  },
  methods: {
    getUserData(key) {
      if (this.state.user) {
        return this.state.user[key];
      }
    },
    doLogout() {
      this.logout();
    }
  }
}
</script>
